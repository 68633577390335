export enum projectView {
  PROJECT_VIEW_TAB = "PROJECT_VIEW_TAB",
  PROJECT_VIEW = "PROJECT_VIEW",
  PROJECT_VIEW_ASYNC = "PROJECT_VIEW_ASYNC",
  PROJECT_MILESTONE_LIST = "PROJECT_MILESTONE_LIST",
  PROJECT_MILESTONE_LIST_ASYNC = "PROJECT_MILESTONE_LIST_ASYNC",
  PROJECT_MILESTONE_LIST_SELECTION = "PROJECT_MILESTONE_LIST_SELECTION",
  PROJECT_MILESTONE_LIST_SELECTION_ASYNC = "PROJECT_MILESTONE_LIST_SELECTION_ASYNC",
  PROJECT_TASK_LIST = "PROJECT_TASK_LIST",
  PROJECT_TASK_LIST_ASYNC = "PROJECT_TASK_LIST_ASYNC",
  TASK_MESSAGE_LIST = "TASK_MESSAGE_LIST",
  TASK_MESSAGE_LIST_ASYNC = "TASK_MESSAGE_LIST_ASYNC",
  TASK_TO_DO_LIST = "TASK_TO_DO_LIST",
  TASK_TO_DO_LIST_ASYNC = "TASK_TO_DO_LIST_ASYNC",
  GET_TASK_PARTICIPATE_LIST = "GET_TASK_PARTICIPATE_LIST",
  GET_TASK_PARTICIPATE_LIST_ASYNC = "GET_TASK_PARTICIPATE_LIST_ASYNC",
  PRIORITY_LIST = "PRIORITY_LIST",
  PRIORITY_LIST_ASYNC = "PRIORITY_LIST_ASYNC",
  GET_TASK_ACTIVITY = "GET_TASK_ACTIVITY",
  GET_TASK_ACTIVITY_ASYNC = "GET_TASK_ACTIVITY_ASYNC",
  GET_TASK_STATUS = "GET_TASK_STATUS",
  GET_TASK_STATUS_ASYNC = "GET_TASK_STATUS_ASYNC",
  PROJECT_VIEW_OVERVIEW = "PROJECT_VIEW_OVERVIEW",
  PROJECT_VIEW_OVERVIEW_ASYNC = "PROJECT_VIEW_OVERVIEW_ASYNC",
  PROJECT_VIEW_ACTIVITIES = "PROJECT_VIEW_ACTIVITIES",
  PROJECT_VIEW_ACTIVITIES_ASYNC = "PROJECT_VIEW_ACTIVITIES_ASYNC"
}
