import { project } from "./project-type";
import { projectView } from "./project-view-type";

const milestone: any = {
  list: {},
  status: undefined,
  message: "",
  total_load: 0,
  total_all: 0
  // milestone_1: { name: "Register to Telco 1", seq: 0, progress: 0, id: 1 }
};
const initialState = {
  childs: [
    {
      label: "Overview",
      value: "overview",
      route: "/project/view/slug/overview"
    },
    {
      label: "Tasks",
      value: "tasks",
      route: "/project/view/slug/tasks"
    }
  ],
  general_data: {
    view_by: [
      { value: "ctz", label: "Recently Created" },
      { value: "pna", label: "Title:A-to-Z" },
      { value: "pnz", label: "Title:Z-to-A" },
      { value: "ppa", label: "Completion: 0% to 100%" },
      { value: "ppz", label: "Completion: 100% to 0%" }
    ],
    status: [
      { value: "not", label: "Not Started" },
      { value: "ovd", label: "Overdue" },
      { value: "inp", label: "In Progress" },
      { value: "upc", label: "Upcoming" },
      { value: "cpt", label: "Completed" }
    ],
    upcoming_due: [
      { value: "now", label: "Today" },
      { value: "twk", label: "This Week" },
      { value: "tmn", label: "This Month" }
    ],
    milestone_view: [
      // { value: 1, label: "Milestone 1" },
      // { value: 2, label: "Milestone 2" },
      // { value: 3, label: "Milestone 3" }
    ],
    priority: [
      // { value: 1, label: "Normal" },
      // { value: 2, label: "Medium" },
      // { value: 3, label: "High" }
    ]
  },
  project_details: {},
  overview: {
    task_progress: [
      {
        name: "Not Started",
        value: 20,
        color: "#FFBB28",
        total: "2",
        donut_label: { value: 0, label: "Task left" }
      },
      {
        name: "Overdue",
        value: 10,
        color: "#FF8042",
        total: "4",
        donut_label: { value: 0, label: "Task left" }
      },
      {
        name: "Upcoming",
        value: 60,
        color: "#0088FE",
        total: "48",
        donut_label: { value: 0, label: "Task left" }
      },
      {
        name: "Completed",
        value: 10,
        color: "#00C49F",
        total: "2",
        donut_label: { value: 0, label: "Task left" }
      }
    ],
    task_upcoming: {
      today: { total: 4 },
      this_week: { total: 5 },
      this_month: { total: 7 }
    },
    project_progress: { value: 45 },
    project_timeline: "12 Feb, 2020 - 20 May, 2020",
    tasks: { total: 50 },
    milestones: { total: 3 },
    participates: { total: 100 }
  },
  activities: {
    start_date: "2019-01-01",
    list: [
      { date: "2020-01-01", count: 3 },
      { date: "2020-01-22", count: 7 },
      { date: "2020-01-30", count: 2 }
    ]
  },
  milestone,
  task: {
    list: [
      {
        task: {
          name: ' Submit "Latitude and Longitude" to Telco',
          total_update: "10"
        },

        status: {
          label: "Pending",
          code: "done",
          color: "#E2445C"
        },
        priority: {
          value: "normal",
          label: "Normal",
          color: "#579BFC"
        },
        follow_up: "Feb 21,2020",
        timeline: "Nov 30, 2019 - Nov 30,2020",
        participate: {
          view: [
            "http://xcelearn.com.my/static/icons/users/1.jpg",
            "http://xcelearn.com.my/static/icons/users/2.jpg",
            "http://xcelearn.com.my/static/icons/users/3.jpg"
          ],
          total_remaining: "+4"
        },
        total_progress: "80",
        last_updated: {
          date: " Nov 31,2020",
          by: "updated by Madam"
        }
      },
      {
        task: {
          name: "Receive offer from Telco",
          total_update: "6"
        },
        type: "Land Management",
        status: {
          label: "In Progress",
          code: "done",
          color: "#579BFC"
        },
        priority: {
          value: "medium",
          label: "Medium",
          color: "#FE8601"
        },
        follow_up: "Nov 27,2021",
        timeline: "Apr 30, 2019 - Feb 30,2020",
        participate: {
          view: [
            "http://xcelearn.com.my/static/icons/users/1.jpg",
            "http://xcelearn.com.my/static/icons/users/2.jpg"
          ],
          total: "4"
        },
        total_progress: "60",
        last_updated: {
          date: " Nov 31,2020",
          by: "updated by Madam"
        }
      },
      {
        task: {
          name: "Propose new Lat long",
          total_update: "1"
        },
        type: "Land Management",
        status: {
          label: "Completed",
          code: "done",
          color: "#00C875"
        },
        priority: {
          value: "medium",
          label: "Medium",
          color: "#FE8601"
        },
        follow_up: "Nov 27,2021",
        timeline: "Nov 30, 2019 - Nov 30,2021",
        participate: {
          view: [
            "http://xcelearn.com.my/static/icons/users/2.jpg",
            "http://xcelearn.com.my/static/icons/users/3.jpg"
          ],
          total: "4"
        },
        total_progress: "30",
        last_updated: {
          date: " Nov 31,2020",
          by: "updated by Madam"
        }
      },
      {
        task: {
          name: " ASSR (Acquisition Site Survey Report)",
          total_update: "1"
        },
        type: "Land Management",
        status: {
          label: "Closed",
          code: "closed",
          color: "#A257DD"
        },
        priority: {
          value: "high",
          label: "High",
          color: "#E2445C"
        },
        follow_up: "Nov 27,2021",
        timeline: "Nov 30, 2019 - Nov 30,2021",
        participate: {
          view: [
            "http://xcelearn.com.my/static/icons/users/2.jpg",
            "http://xcelearn.com.my/static/icons/users/3.jpg"
          ],
          total: "4"
        },
        total_progress: "30",
        last_updated: {
          date: " Nov 31,2020",
          by: "updated by Madam"
        }
      }
    ]
  },
  is_view_detail: false
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case projectView.PROJECT_VIEW_TAB:
      state.childs = action.data;
      return { ...state };
    case project.IS_VIEW_DETAIL:
      state.is_view_detail = action.status;
      return { ...state };
    case projectView.PROJECT_VIEW_ASYNC:
      state.project_details = action.data;
      return { ...state };
    case projectView.PROJECT_VIEW_OVERVIEW_ASYNC:
      state.overview = action.data;
      return { ...state };
    case projectView.PROJECT_VIEW_ACTIVITIES_ASYNC:
      state.activities.list = action.data;
      state.activities.start_date = action.start_date;
      return { ...state };
    case projectView.PRIORITY_LIST_ASYNC:
      state.general_data.priority = action.data;
      return { ...state };
    case projectView.PROJECT_MILESTONE_LIST_SELECTION_ASYNC:
      state.general_data.milestone_view = action.data;
      return { ...state };
    case projectView.PROJECT_MILESTONE_LIST_ASYNC:
      state.milestone.list = action.data;
      state.milestone.status = action.status;
      state.milestone.message = action.message;

      return { ...state };
    case projectView.PROJECT_TASK_LIST_ASYNC:
      const milestoneId = action.milestone_id;
      if (milestoneId && state.milestone.list[milestoneId]) {
        state.milestone.list[milestoneId].childs = action.data;
        state.milestone.list[milestoneId].status = action.status;
        state.milestone.list[milestoneId].message = action.message
          ? action.message
          : "";

        state.milestone.list[milestoneId].total_all = action.total_all
          ? action.total_all
          : 0;
        state.milestone.list[milestoneId].total_load = action.total_load
          ? action.total_load
          : 0;

        //create custom message to task list
        state.milestone.total_load = 0;
        state.milestone.total_all = 0;
        for (const x in Object.keys(state.milestone.list)) {
          const item =
            state.milestone.list[Object.keys(state.milestone.list)[x]];
          state.milestone.total_load =
            state.milestone.total_load + item.total_load;
          state.milestone.total_all =
            state.milestone.total_all + item.total_all;
        }
      }

      return { ...state };
    default:
      return state;
  }
};
export default todos;
