import { applyMiddleware, compose, combineReducers, createStore } from "redux";
import { History } from "history";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { connectRouter, routerMiddleware } from "connected-react-router";
import app from "./app-reducer";
import drawer from "./drawer-reducer";
import user from "./user-reducer";
import sites from "../routes/sites/store/sites-reducer";
import siteDetails from "../routes/site-details/store/site-reducer";
import project from "../routes/project/store/project-reducer";
import projectView from "../routes/project/store/project-view-reducer";
import task from "../routes/project/store/task-reducer";
import myAssignment from "../routes/my-assignment/store/assignment-reducer";
import usersManagement from "../routes/management/users/store/users-reducer";
import usersProfile from "../routes/management/users/store/profile-reducer";
import myAccount from "../routes/my-account/store/profile-reducer";
import generalData from "./general-data/general-data-reducer";

import rootSaga from "./root-sagas";

const createRootReducer = (history: History) =>
  combineReducers({
    redux: combineReducers({
      app,
      drawer,
      user,
      general_data: generalData,
      modules: combineReducers({
        project: combineReducers({
          list: project,
          view: projectView,
          task: task,
        }),
        sites: combineReducers({
          list: sites,
          view: siteDetails,
        }),
        management: combineReducers({
          users: combineReducers({
            list: usersManagement,
            view: usersProfile,
          }),
        }),
        account: combineReducers({
          profile: myAccount,
        }),
        my_assignment: myAssignment,
      }),
    }),
    router: connectRouter(history),
  });

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

export default function configureStore(history: History) {
  //Init middlewares
  const middlewares = [routerMiddleware(history), sagaMiddleware];

  //Init enhancer
  const enhancer = compose(applyMiddleware(...middlewares));

  //Store creation
  const store = createStore(createRootReducer(history), enhancer);
  sagaMiddleware.run(rootSaga);
  return store;
}
