import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { app } from "../../../stores/type";
import Axios from "axios";
import { authHeader } from "../../../services/auth-header";

export const getSitesList = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/sites/list`, {
    ...authHeader(),
    params: { ...action.params },
  })
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};
