import { project } from "./project-type";
const initialState = {
  childs: [
    {
      label: "Overview",
      value: "overview",
      route: "/project/overview"
    },
    {
      label: "Projects",
      value: "project",
      route: "/project/project"
    }
  ],
  general_data: {
    view_by: [
      { value: "ctz", label: "Recently Created" },
      { value: "pna", label: "Title:A-to-Z" },
      { value: "pnz", label: "Title:Z-to-A" },
      { value: "ppa", label: "Completion: 0% to 100%" },
      { value: "ppz", label: "Completion: 100% to 0%" }
    ],
    categories: [
      //{ value: 1, label: "Infrastructure Provider" },
    ],
    project_person_incharge: [
      //{ value: 1, label: "Madam Zulaikha" }
    ],

    project_subcon: [
      // { value: 1, label: "Mestron" },
    ],
    project_NFP: [
      // { value: 1, label: "Shorefield Communication Sdn Bhd" }
    ]
  },
  overview: {
    projects_progress: [
      {
        name: "Not Started",
        value: 0,
        color: "#FFBB28",
        total: "0",
        donut_label: { value: 0, label: "Project left" }
      },
      {
        name: "Overdue",
        value: 0,
        color: "#FF8042",
        total: "0",
        donut_label: { value: 0, label: "Project left" }
      },
      {
        name: "Upcoming",
        value: 0,
        color: "#0088FE",
        total: "0",
        donut_label: { value: 0, label: "Project left" }
      },
      {
        name: "Completed",
        value: 0,
        color: "#00C49F",
        total: "0",
        donut_label: { value: 0, label: "Project left" }
      }
    ],
    projects_approval_status: [
      {
        name: "Live",
        color: "#00C49F",
        total: "0"
      },
      {
        name: "Pending",
        color: "#FFBB28",
        total: "0"
      },
      {
        name: "Rejected",
        color: "#FF8042",
        total: "0"
      }
    ],
    projects: { total: 0 }
  },
  project: {
    total_all: 0,
    total_load: 0,
    message: "",
    status: undefined,
    list: [
      // {
      //   project: {
      //     id: 1,
      //     name: "Telecommunication Tower Development",
      //     // work of scope
      //     desc:
      //       "Building two network towers support 5g for celcom user throughputs close to 6Gbps, and will be further optimised during the live field trials within areas such as Seksyen 14, Crystal Crown Hotel, Petaling Jaya City Council (MBPJ) Tower, and Taman Jaya LRT Station. These 5G macro sites covers approximately 1.22 square kilometre wide, reaching about 450 households, 130 shop lots, offices, shopping areas, outdoor spaces with an estimated population of over 4,600 in the vicinity. "
      //   },
      //   category: {
      //     value: 2,
      //     label: "Infrastructure Provider"
      //   },
      //   status: {
      //     //project status
      //     value: 1,
      //     label: "Live"
      //   },
      //   progress: [
      //     // total task status
      //     { label: "Closed", total: 1, color: "#A257DD" },
      //     { label: "Completed", total: 2, color: "#00C875" },
      //     { label: "Upcoming", total: 27, color: "#579BFC" },
      //     { label: "Pending", total: 1, color: "#E2445C" }
      //   ],
      //   follow_up: "Nov 27,2021", // last followup date in task
      //   timeline: "Nov 30, 2019 - Nov 30,2021", // project duration
      //   last_updated: {
      //     // after update something in task
      //     date: " Nov 31,2020",
      //     by: "updated by Madam"
      //   },
      //   participate: {
      //     //  total user assignees and involved
      //     view: [
      //       "http://xcelearn.com.my/static/icons/users/1.jpg",
      //       "http://xcelearn.com.my/static/icons/users/2.jpg",
      //       "http://xcelearn.com.my/static/icons/users/3.jpg" //limit 3 for preview
      //     ],
      //     total_remaining: "+4" // total all users
      //   }
      // },
      // {
      //   project: {
      //     id: 2,
      //     name: "Cell Tower Construction",
      //     desc:
      //       "Building two network towers support 5g for celcom user throughputs close to 6Gbps, and will be further optimised during the live field trials within areas such as Seksyen 14, Crystal Crown Hotel, Petaling Jaya City Council (MBPJ) Tower, and Taman Jaya LRT Station. These 5G macro sites covers approximately 1.22 square kilometre wide, reaching about 450 households, 130 shop lots, offices, shopping areas, outdoor spaces with an estimated population of over 4,600 in the vicinity. "
      //   },
      //   category: {
      //     value: 2,
      //     label: "Infrastructure Provider"
      //   },
      //   status: {
      //     value: 1,
      //     label: "Live"
      //   },
      //   progress: [
      //     { label: "Completed", total: 2, color: "#00C875" },
      //     { label: "Upcoming", total: 27, color: "#579BFC" },
      //     { label: "Pending", total: 1, color: "#E2445C" }
      //   ],
      //   follow_up: "Nov 27,2021",
      //   timeline: "Nov 30, 2019 - Nov 30,2021",
      //   last_updated: {
      //     date: " Nov 31,2020",
      //     by: "updated by Madam"
      //   },
      //   participate: {
      //     view: [
      //       "http://xcelearn.com.my/static/icons/users/1.jpg",
      //       "http://xcelearn.com.my/static/icons/users/2.jpg",
      //       "http://xcelearn.com.my/static/icons/users/3.jpg"
      //     ],
      //     total_remaining: "+4"
      //   }
      // }
    ]
  }
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    // case project.PROJECT_CATEGORY_LIST_ASYNC:
    //   state.general_data.categories = action.data;
    //   return { ...state };
    case project.PROJECT_PERSON_INCHARGE_LIST_ASYNC:
      state.general_data.project_person_incharge = action.data;
      return { ...state };
    case project.PROJECT_SUBCON_LIST_ASYNC:
      state.general_data.project_subcon = action.data;
      return { ...state };
    case project.PROJECT_NFP_PARTNERS_LIST_ASYNC:
      state.general_data.project_NFP = action.data;
      return { ...state };
    case project.PROJECT_LIST_ASYNC:
      state.project.list = action.data;
      state.project.status = action.status;
      state.project.message = action.message;
      state.project.total_all = action.total_all;
      state.project.total_load = action.total_load;
      return { ...state };
    case project.PROJECT_OVERVIEW_ASYNC:
      state.overview = action.data;
      return { ...state };
    default:
      return state;
  }
};
export default todos;
