import { call, takeLatest, put, delay } from "redux-saga/effects";
import { GD_type } from "./general-data-type";
import {
  getCompanyList,
  getLandCategoryList,
  getSiteTypeList,
  getStateList,
  getAreaList,
  getTowerStructureList,
  getUserList,
  getBuildingStatus,
} from "./general-data-axios";

function* async_getUserList(action: any) {
  try {
    yield put({
      type: GD_type.GD__USER_LIST_ASYNC,
      is_loading: true,
    });

    const data = yield call(getUserList, action);

    yield put({
      type: GD_type.GD__USER_LIST_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* GD_watch_getUserList() {
  yield takeLatest(GD_type.GD__USER_LIST, async_getUserList);
}

function* async_getStateList(action: any) {
  try {
    yield put({
      type: GD_type.GD__STATE_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getStateList, action);
    yield put({
      type: GD_type.GD__STATE_LIST_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* GD_watch_getStateList() {
  yield takeLatest(GD_type.GD__STATE_LIST, async_getStateList);
}

function* async_getAreaList(action: any) {
  try {
    yield put({
      type: GD_type.GD__AREA_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getAreaList, action);
    yield put({
      type: GD_type.GD__AREA_LIST_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* GD_watch_getAreaList() {
  yield takeLatest(GD_type.GD__AREA_LIST, async_getAreaList);
}

function* async_getCompanyList(action: any) {
  try {
    yield put({
      type: GD_type.GD__COMPANY_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getCompanyList, action);
    yield put({
      type: GD_type.GD__COMPANY_LIST_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* GD_watch_getCompanyList() {
  yield takeLatest(GD_type.GD__COMPANY_LIST, async_getCompanyList);
}

function* async_getSiteTypeList(action: any) {
  try {
    yield put({
      type: GD_type.GD__SITES_TYPE_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getSiteTypeList, action);
    yield put({
      type: GD_type.GD__SITES_TYPE_LIST_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* GD_watch_getSiteTypeList() {
  yield takeLatest(GD_type.GD__SITES_TYPE_LIST, async_getSiteTypeList);
}

function* async_getTowerStructureList(action: any) {
  try {
    yield put({
      type: GD_type.GD__TOWER_STRUCTURE_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getTowerStructureList, action);
    yield put({
      type: GD_type.GD__TOWER_STRUCTURE_LIST_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* GD_watch_getTowerStructureList() {
  yield takeLatest(
    GD_type.GD__TOWER_STRUCTURE_LIST,
    async_getTowerStructureList
  );
}

function* async_getBuildingStatus(action: any) {
  try {
    yield put({
      type: GD_type.GD__PROPERTY_BUILDING_STATUS_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getBuildingStatus, action);
    yield put({
      type: GD_type.GD__PROPERTY_BUILDING_STATUS_LIST_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* GD_watch_getBuildingStatus() {
  yield takeLatest(
    GD_type.GD__PROPERTY_BUILDING_STATUS_LIST,
    async_getBuildingStatus
  );
}

function* async_getLandCategoryList(action: any) {
  try {
    yield put({
      type: GD_type.GD__SITE_LAND_CATEGORY_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getLandCategoryList, action);
    yield put({
      type: GD_type.GD__SITE_LAND_CATEGORY_LIST_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* GD_watch_getLandCategoryList() {
  yield takeLatest(
    GD_type.GD__SITE_LAND_CATEGORY_LIST,
    async_getLandCategoryList
  );
}
