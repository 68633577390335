import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { project } from "./project-type";
import { app } from "../../../stores/type";
import Axios from "axios";
import { authHeader } from "../../../services/auth-header";

export const getCategoryList = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/project/category`, authHeader())
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getSubconList = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/project/supplier`, authHeader())
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getPersonInchargeList = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/project/participate/select-users`,
    authHeader()
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getNFPList = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/project/nfp-partners`, authHeader())
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getProjectList = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/project/list`, {
    ...authHeader(),
    params: { ...action.params }
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getProjectOverview = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/project/overview`, {
    ...authHeader()
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};
