import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
// import Viewer from './viewer';

interface Props {
  title: String;
  desc: string;
  redux: {
    user: {
      login: {
        status: boolean;
      };
    };
    app: {
      title: String;
    };
  };
  location: { pathname: String };
}
class mainLayout extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const props = this.props;
    const redux = props.redux;
    console.log("main-wrapper");
    console.log(props);
    return (
      <div
        className={`view-wrapper  ${props.location.pathname.replace(/\//, "")}`}
      >
        <Helmet>
          <title>{redux.app.title}</title>
          <meta charSet="utf-8" />
          <meta name="description" content="Tower Management System" />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Orbitron:900&display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
        </Helmet>
        {/* {props.location.pathname == '/' ? (
          <>
            <Redirect to="/login" />
          </>
        ) : props.location.pathname != '/login' ? (
          <Viewer location={props.location}>{props.children}</Viewer>
        ) : (
          props.children
        )} */}
        {props.children}
      </div>
    );
  }
}
const mapStateToProps = (state: any) => state;
export default connect(mapStateToProps, null)(mainLayout);
