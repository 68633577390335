import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { app } from "../../../stores/type";
import Axios from "axios";
import { authHeader } from "../../../services/auth-header";

export const getSiteDetails = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/sites/details/${action.id}`, {
    ...authHeader(),
    params: { ...action.params },
  })
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};

export const getMediaList = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/sites/${action.site_id}/gallery/list`,
    {
      ...authHeader(),
      params: { ...action.params },
    }
  )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};
