import { call, takeLatest, put, delay } from "redux-saga/effects";
import { usersType } from "./users-type";
import {
  getUserList,
  getCompanyList,
  getDepartmentList,
  getGenderList
} from "./users_axios";

function* usersListAsync(action: any) {
  try {
    const data = yield call(getUserList, action);
    yield put({
      type: usersType.GET_USER_LIST_ASYNC,
      data: data.data,
      message: data.message,
      status: data.status,
      total_Load: data.total_Load,
      total_all: data.total_all
    });
  } catch (error) {
    throw error;
  }
}

export function* watchManagementUsersList() {
  yield takeLatest(usersType.GET_USER_LIST, usersListAsync);
}

function* userCompanyListAsync(action: any) {
  try {
    const data = yield call(getCompanyList, action);
    yield put({
      type: usersType.GET_USER_COMPANY_LIST_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchUsersCompanyList() {
  yield takeLatest(usersType.GET_USER_COMPANY_LIST, userCompanyListAsync);
}

function* userDepartmentListAsync(action: any) {
  try {
    const data = yield call(getDepartmentList, action);
    yield put({
      type: usersType.GET_USER_DEPARTMENT_LIST_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchUsersDepartmentList() {
  yield takeLatest(usersType.GET_USER_DEPARTMENT_LIST, userDepartmentListAsync);
}
function* userGenderListAsync(action: any) {
  try {
    const data = yield call(getGenderList, action);
    yield put({
      type: usersType.GET_USER_GENDER_LIST_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchUsersGenderList() {
  yield takeLatest(usersType.GET_USER_GENDER_LIST, userGenderListAsync);
}
