import { call, takeLatest, put, delay } from "redux-saga/effects";
import { types } from "./site-type";
import { getSiteDetails, getMediaList } from "./site-axios";

function* async_getSiteDetails(action: any) {
  try {
    yield put({
      type: types.SITES_DETAILS_ASYNC,
      is_loading: true,
    });
    const data = yield call(getSiteDetails, action);
    yield put({
      type: types.SITES_DETAILS_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* SITES_watch_getSiteDetails() {
  yield takeLatest(types.SITES_DETAILS, async_getSiteDetails);
}

function* async_getMediaList(action: any) {
  try {
    yield put({
      type: types.SITE_MEDIA_LIST_ASYNC,
      is_loading: true,
    });
    console.log("test");
    const data = yield call(getMediaList, action);
    yield put({
      type: types.SITE_MEDIA_LIST_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* SITES_watch_getMediaList() {
  yield takeLatest(types.SITE_MEDIA_LIST, async_getMediaList);
}
