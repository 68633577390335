import { call, takeLatest, put, delay } from "redux-saga/effects";
import { types } from "./sites-type";
import { getSitesList } from "./sites-axios";

function* async_getSitesList(action: any) {
  try {
    yield put({
      type: types.SITES_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getSitesList, action);
    yield put({
      type: types.SITES_LIST_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* watch_getSitesList() {
  yield takeLatest(types.SITES_LIST, async_getSitesList);
}
