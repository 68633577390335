import { types } from "./sites-type";
const initialState: any = {
  childs: [
    {
      label: "Overview",
      value: "overview",
      route: "/sites/overview",
    },
    {
      label: "Sites",
      value: "sites",
      route: "/sites/sites",
    },
    {
      label: "Map",
      value: "map",
      route: "/sites/map",
    },
  ],
  general_data: {
    view_by: [
      { value: "ctz", label: "Recently Created" },
      { value: "pna", label: "Title:A-to-Z" },
      { value: "pnz", label: "Title:Z-to-A" },
    ],
  },

  overview: {},
  sites: {
    total_all: 0,
    total_load: 0,
    message: "",
    is_loading: true,
    status: undefined,
    data: [
      // {
      //   site: {
      //     id: 1,
      //     name: "PINGGIR BANDAR RIMBAYU",
      //     lat: "2.9198° N",
      //     long: "101.7809° E",
      //   },
      //   address: {
      //     state: "Selangor",
      //     area: "Bandar Baru Bangi",
      //     postcode: "34560",
      //     address_detail: "Lot 15502, mukim tanjung 12",
      //   },
      //   site_type: { value: 1, label: "LAMPOLE 24m", color: "#BDBDBD" },
      //   structure_type: { value: 1, label: "LAMPOLE 24m", color: "#BDBDBD" },
      //   project_status: {
      //     value: 1,
      //     label: "ALREADY SECURE WITH OWNER",
      //     color: "#2196F3",
      //   },
      //   land_owner: {
      //     name: "Haji Aris",
      //     type: "PRIVATE",
      //   },
      //   land_category: {
      //     value: 1,
      //     label: "ALREADY SECURE WITH OWNER",
      //     color: "#2196F3",
      //   },
      //   last_updated: {
      //     date: " Nov 31,2020",
      //     by: { first_name: "Izzuddin" },
      //   },
      // },
    ],
  },
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SITES_LIST_ASYNC:
      state.sites.is_loading = action.is_loading;
      if (!action.is_loading) {
        state.sites.data = action.data.data;
        state.sites.status = action.data.status;
        state.sites.message = action.data.message;
        state.sites.total_all = action.data.total_all;
        state.sites.total_load = action.data.total_load;
      }

      return { ...state };

    default:
      return state;
  }
};
export default todos;
