import { myAccount } from "./my-account-type";

const initialState = {
  childs: [
    {
      label: "Overview",
      value: "overview",
      route: "/my-account/profile/overview"
    },
    {
      label: "Activity Log",
      value: "activity-log",
      route: "/my-account/profile/activity-log"
    }
  ],
  overview: {
    task_progress: [
      {
        name: "Not Started",
        color: "#FFBB28",
        total: "0"
      },
      {
        name: "Overdue",
        color: "#FF8042",
        total: "0"
      },
      {
        name: "Upcoming",
        color: "#0088FE",
        total: "0"
      },
      {
        name: "Completed",
        color: "#00C49F",
        total: "0"
      }
    ],
    project_progress: [
      {
        name: "Not Started",
        color: "#FFBB28",
        total: "0"
      },
      {
        name: "Overdue",
        color: "#FF8042",
        total: "0"
      },
      {
        name: "Upcoming",
        color: "#0088FE",
        total: "0"
      },
      {
        name: "Completed",
        color: "#00C49F",
        total: "0"
      }
    ],
    tasks: { total: 0 },
    projects: { total: 0 }
  },
  activities: {
    start_date: "2019-07-01",
    list: []
  },
  user_details: {
    // first_name: "Mohamad Izzuddin",
    // last_name: "AB Raffar",
    // full_name: "Muhamad Izzuddin AB Raffar",
    // email: "izzuddin@djati.com.my",
    // phone_number: "+601111122311",
    // gender: {
    //   value: "male",
    //   label: "Male"
    // },
    // company: {
    //   value: 1,
    //   label: "Dasar Jati Sdn. Bhd"
    // },
    // department: {
    //   value: 1,
    //   label: "Finance"
    // },
    // address: "Lot 1662, Kampung Bendang Kerian,16200 Tumpat Kelantan",
    // bio:
    //   "Experienced Front-end Developer with a demonstrated history of working in the application development industry, Skilled HTML, JS $ more."
  },
  activity: {
    message: "",
    status: undefined,
    list: {}
  }
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case myAccount.GET_MY_PROFILE_ASYNC:
      state.user_details = action.data;
      return { ...state };
    case myAccount.GET_MY_ACTIVITY_ASYNC:
      state.activity.list = action.data;
      state.activity.status = action.status;
      state.activity.message = action.message;
      return { ...state };
    case myAccount.MY_PROFILE_OVERVIEW_ASYNC:
      state.overview = action.data;
      console.log("state.overview");
      console.log(state.overview);
      return { ...state };
    case myAccount.MY_PROFILE_OVERVIEW_ACTIVITIES_ASYNC:
      state.activities.list = action.data;
      state.activities.start_date = action.start_date;
      return { ...state };
    default:
      return state;
  }
};
export default todos;
