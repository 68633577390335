import { projectView } from "./project-view-type";
const initialState = {
  general_data: {
    status: [
      // {
      //   value: 1,
      //   label: "In Progress"
      // },
      // {
      //   value: 2,
      //   label: "Completed"
      // }
    ],
    priority: [
      {
        value: 1,
        label: "Normal"
      },
      {
        value: 2,
        label: "Medium"
      },
      {
        value: 3,
        label: "High"
      }
    ]
  },
  to_dos: {
    completed: {
      total: 2,
      percent: 80
    },
    status: undefined,
    list: []
  },
  participate: {
    status: undefined,
    list: []
  },
  update: {
    status: undefined,
    list: [
      // {
      //   id: "1",
      //   name: "Madam Zulaikha",
      //   icon: "http://xcelearn.com.my/static/icons/users/2.jpg",
      //   company: "Dasar Jati Sdn Bhd",
      //   unit: "Business Development",
      //   update_time: { default: "Jan 01,2020", moment: "16 hours ago" },
      //   update_message:
      //     "New task openend. Click View Details or Task Info to get the informations.",
      //   update_icon: "insert_comment",
      //   type: "comment-box"
      // },
      // {
      //   id: "2",
      //   name: "Izzuddin",
      //   icon: "http://xcelearn.com.my/static/icons/users/1.jpg",
      //   company: "Dasar Jati Sdn Bhd",
      //   unit: "Software",
      //   update_time: { default: "Jan 02,2020", moment: "15 hours ago" },
      //   update_message: "changed task status from OPEN to REASSIGNED",
      //   update_icon: "group_work",
      //   type: "status-box"
      // },
      // {
      //   id: "2",
      //   name: "Izzuddin",
      //   icon: "http://xcelearn.com.my/static/icons/users/1.jpg",
      //   company: "Dasar Jati Sdn Bhd",
      //   unit: "Software",
      //   update_time: { default: "Jan 02,2020", moment: "15 hours ago" },
      //   update_message: "changed task status from OPEN to REASSIGNED",
      //   update_icon: "group_work",
      //   type: "status-box"
      // },
      // {
      //   id: "3",
      //   name: "Jeff",
      //   icon: "http://xcelearn.com.my/static/icons/users/3.jpg",
      //   company: "Dasar Jati Sdn Bhd",
      //   unit: "Software",
      //   update_time: { default: "Jan 01,2020", moment: "16 hours ago" },
      //   update_message: "Done",
      //   update_icon: "insert_comment",
      //   type: "comment-box"
      // },
      // {
      //   id: "3",
      //   name: "Jeff",
      //   icon: "http://xcelearn.com.my/static/icons/users/3.jpg",
      //   company: "Dasar Jati Sdn Bhd",
      //   unit: "Software",
      //   update_time: { default: "Jan 01,2020", moment: "16 hours ago" },
      //   update_message: "Done",
      //   update_icon: "insert_comment",
      //   type: "comment-box"
      // }
    ]
  },
  activity: {
    message: "",
    status: undefined,
    list: {}
  }
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case projectView.TASK_MESSAGE_LIST_ASYNC:
      state.update.list = action.data;
      state.update.status = action.status;
      return { ...state };
    case projectView.TASK_TO_DO_LIST_ASYNC:
      state.to_dos.list = action.data;
      state.to_dos.status = action.status;
      state.to_dos.completed = action.completed;
      return { ...state };
    case projectView.GET_TASK_PARTICIPATE_LIST_ASYNC:
      state.participate.list = action.data;
      state.participate.status = action.status;
      return { ...state };
    case projectView.GET_TASK_ACTIVITY_ASYNC:
      state.activity.list = action.data;
      state.activity.status = action.status;
      state.activity.message = action.message;
      return { ...state };

    case projectView.GET_TASK_STATUS_ASYNC:
      state.general_data.status = action.data;
      return { ...state };
    default:
      return state;
  }
};
export default todos;
