import { call, takeLatest, put, delay } from "redux-saga/effects";
import { app } from "../stores/type";
import { userLogin, userLogout } from "./user-service";

function* userLoginAsync(action: any) {
  try {
    const data = yield call(userLogin, action);
    if (data.status) {
      localStorage.setItem("user", JSON.stringify(data));
      yield put({
        type: app.LOGIN_STATUS_ASYNC,
        status: true,
        message: data.message
      });
      yield delay(500);
      action.history.push("/project");
      yield put({
        type: app.LOGIN_STATUS_ASYNC,
        status: true,
        message: undefined
      });
    } else {
      yield put({
        type: app.LOGIN_STATUS_ASYNC,
        status: false,
        message: data.message
      });
    }
  } catch (error) {
    throw error;
  }
}

export function* watchUserLogin() {
  yield takeLatest(app.LOGIN_STATUS, userLoginAsync);
}

function* userLogoutAsync(action: any) {
  try {
    const data = yield call(userLogout, action);
    // if (data.status) {
    localStorage.removeItem("user");
    yield delay(1000);
    window.location.href = "/login";
    // action.history.push("/login");
    // yield put({
    //   type: app.LOGOUT_STATUS_ASYNC,
    //   status: false
    // });
    // } else {
    //   localStorage.removeItem("user");
    //   window.location.href = "/login";
    // }
  } catch (error) {
    throw error;
  }
}

export function* watchUserLogout() {
  yield takeLatest(app.LOGOUT_STATUS, userLogoutAsync);
}
