import React from "react";
import { projectView } from "./project-view-type";
import { app } from "../../../stores/type";
import Axios from "axios";
import { authHeader } from "../../../services/auth-header";

export const getProjectbyId = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/project/view/${action.id}`,
    authHeader()
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getMilestoneList = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/project/${action.project_id}/milestone/list`,
    {
      ...authHeader(),
      params: { ...action.params }
    }
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getMilestoneListSelection = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/project/${action.project_id}/milestone/list/selection`,
    authHeader()
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getTaskList = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/project/${action.project_id}/milestone/${action.milestone_id}/task/list`,
    {
      ...authHeader(),
      params: { ...action.params }
    }
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getProjectParticipateList = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/project/${action.project_id}/participate/list/${action.task_id}`,
    {
      ...authHeader(),
      params: { ...action.params }
    }
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getPriorityList = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/project/priority/list`, {
    ...authHeader()
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getProjectViewOverview = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/project/${action.id}/view/overview`, {
    ...authHeader()
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getProjectViewActivities = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/project/${action.id}/view/activities`,
    {
      ...authHeader()
    }
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};
