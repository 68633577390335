export enum project {
  IS_VIEW_DETAIL = "IS_VIEW_DETAIL",
  PROJECT_CATEGORY_LIST = "PROJECT_CATEGORY_LIST",
  PROJECT_CATEGORY_LIST_ASYNC = "PROJECT_CATEGORY_LIST_ASYNC",
  PROJECT_SUBCON_LIST = "PROJECT_SUBCON_LIST",
  PROJECT_SUBCON_LIST_ASYNC = "PROJECT_SUBCON_LIST_ASYNC",
  PROJECT_PERSON_INCHARGE_LIST = "PROJECT_PERSON_INCHARGE_LIST",
  PROJECT_PERSON_INCHARGE_LIST_ASYNC = "PROJECT_PERSON_INCHARGE_LIST_ASYNC",
  PROJECT_NFP_PARTNERS_LIST = "PROJECT_NFP_PARTNERS_LIST",
  PROJECT_NFP_PARTNERS_LIST_ASYNC = "PROJECT_NFP_PARTNERS_LIST_ASYNC",
  PROJECT_LIST = "PROJECT_LIST_LIST",
  PROJECT_LIST_ASYNC = "PROJECT_LIST_ASYNC",
  PROJECT_OVERVIEW = "PROJECT_OVERVIEW",
  PROJECT_OVERVIEW_ASYNC = "PROJECT_OVERVIEW_ASYNC"
}
