export enum usersType {
  GET_USER_LIST = "GET_USER_LIST",
  GET_USER_LIST_ASYNC = "GET_USER_LIST_ASYNC",
  GET_USER_PROFILE = "GET_USER_PROFILE",
  GET_USER_PROFILE_ASYNC = "GET_USER_PROFILE_ASYNC",
  GET_USER_COMPANY_LIST = "GET_USER_COMPANY_LIST",
  GET_USER_COMPANY_LIST_ASYNC = "GET_USER_COMPANY_LIST_ASYNC",
  GET_USER_DEPARTMENT_LIST = "GET_USER_DEPARTMENT_LIST",
  GET_USER_DEPARTMENT_LIST_ASYNC = "GET_USER_DEPARTMENT_LIST_ASYNC",
  GET_USER_GENDER_LIST = "GET_USER_GENDER_LIST",
  GET_USER_GENDER_LIST_ASYNC = "GET_USER_GENDER_LIST_ASYNC",
  GET_USER_ACTIVITY = "GET_USER_ACTIVITY",
  GET_USER_ACTIVITY_ASYNC = "GET_USER_ACTIVITY_ASYNC",
  USER_PROFILE_OVERVIEW = "USER_PROFILE_OVERVIEW",
  USER_PROFILE_OVERVIEW_ASYNC = "USER_PROFILE_OVERVIEW_ASYNC",
  USER_PROFILE_OVERVIEW_ACTIVITIES = "USER_PROFILE_OVERVIEW_ACTIVITIES",
  USER_PROFILE_OVERVIEW_ACTIVITIES_ASYNC = "USER_PROFILE_OVERVIEW_ACTIVITIES_ASYNC"
}
