import { call, takeLatest, put, delay, takeEvery } from "redux-saga/effects";
import { assignment } from "./assignment-type";
import {
  getTaskList,
  getProjectList,
  getAssignmentActivities,
  getAssignmentOverview
} from "./assignment-axios";

function* assignmentListAsync(action: any) {
  try {
    const data = yield call(getTaskList, action);
    yield put({
      type: assignment.ASSIGNMENT_LIST_ASYNC,
      data: data.data,
      status: data.status,
      message: data.message,
      total_Load: data.total_Load,
      total_all: data.total_all
    });
  } catch (error) {
    throw error;
  }
}

export function* watchAssignmentList() {
  yield takeEvery(assignment.ASSIGNMENT_LIST, assignmentListAsync);
}

function* myProjectListAsync(action: any) {
  try {
    const data = yield call(getProjectList, action);
    yield put({
      type: assignment.MY_PROJECT_lIST_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchMyProjectListList() {
  yield takeEvery(assignment.MY_PROJECT_lIST, myProjectListAsync);
}

function* getAssignmentAsync(action: any) {
  try {
    const data = yield call(getAssignmentOverview, action);
    yield put({
      type: assignment.ASSIGNMENT_OVERVIEW_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchAssignmentOverview() {
  yield takeLatest(assignment.ASSIGNMENT_OVERVIEW, getAssignmentAsync);
}

function* getAssignmentActivityAsync(action: any) {
  try {
    const data = yield call(getAssignmentActivities, action);
    yield put({
      type: assignment.ASSIGNMENT_ACTIVITIES_ASYNC,
      data: data.data,
      start_date: data.start_date
    });
  } catch (error) {
    throw error;
  }
}

export function* watchAssignmentActivity() {
  yield takeLatest(
    assignment.ASSIGNMENT_ACTIVITIES,
    getAssignmentActivityAsync
  );
}
