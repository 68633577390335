import { call, takeLatest, put, delay } from "redux-saga/effects";
import { myAccount } from "./my-account-type";
import {
  getUserProfile,
  getUserActivity,
  getProfileActivitiesDashboard,
  getProfileOverviewDashboard
} from "./profile-axios";

function* getProfileAsync(action: any) {
  try {
    const data = yield call(getUserProfile, action);
    yield put({
      type: myAccount.GET_MY_PROFILE_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchMyProfile() {
  yield takeLatest(myAccount.GET_MY_PROFILE, getProfileAsync);
}

function* getMyActivitiesAsync(action: any) {
  try {
    const data = yield call(getUserActivity, action);
    yield put({
      type: myAccount.GET_MY_ACTIVITY_ASYNC,
      data: data.data,
      status: data.status,
      message: data.message
    });
  } catch (error) {
    throw error;
  }
}

export function* watchMyActivities() {
  yield takeLatest(myAccount.GET_MY_ACTIVITY, getMyActivitiesAsync);
}

function* getProfileOverviewDashboardAsync(action: any) {
  try {
    const data = yield call(getProfileOverviewDashboard, action);
    yield put({
      type: myAccount.MY_PROFILE_OVERVIEW_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProfileOverviewDashboard() {
  yield takeLatest(
    myAccount.MY_PROFILE_OVERVIEW,
    getProfileOverviewDashboardAsync
  );
}

function* getProfileActivitiesDashboardAsync(action: any) {
  try {
    const data = yield call(getProfileActivitiesDashboard, action);
    yield put({
      type: myAccount.MY_PROFILE_OVERVIEW_ACTIVITIES_ASYNC,
      data: data.data,
      start_date: data.start_date
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProfileActivitiesDashboard() {
  yield takeLatest(
    myAccount.MY_PROFILE_OVERVIEW_ACTIVITIES,
    getProfileActivitiesDashboardAsync
  );
}
