import React from "react";
import { projectView } from "./project-view-type";
import { app } from "../../../stores/type";
import Axios from "axios";
import { authHeader } from "../../../services/auth-header";

export const getTaskMessageList = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/project/task/${action.task_id}/message/list`,
    authHeader()
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getTaskToDoList = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/project/task/${action.task_id}/to-do/list`,
    authHeader()
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getTaskActivities = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/project/task/${action.task_id}/activities`,
    {
      ...authHeader(),
      params: { ...action.params }
    }
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getTaskStatus = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/project/task/status`, authHeader())
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};
