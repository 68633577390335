import { call, takeLatest, put, delay } from "redux-saga/effects";
import { project } from "./project-type";
import {
  getCategoryList,
  getSubconList,
  getPersonInchargeList,
  getNFPList,
  getProjectList,
  getProjectOverview
} from "./project-axios";

function* categoryListAsync(action: any) {
  try {
    const data = yield call(getCategoryList, action);
    yield put({
      type: project.PROJECT_CATEGORY_LIST_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProjectCategoryList() {
  yield takeLatest(project.PROJECT_CATEGORY_LIST, categoryListAsync);
}

function* subconListAsync(action: any) {
  try {
    const data = yield call(getSubconList, action);
    yield put({
      type: project.PROJECT_SUBCON_LIST_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProjectSubconList() {
  yield takeLatest(project.PROJECT_SUBCON_LIST, subconListAsync);
}

function* personInchargeListAsync(action: any) {
  try {
    const data = yield call(getPersonInchargeList, action);
    yield put({
      type: project.PROJECT_PERSON_INCHARGE_LIST_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProjectPersonInchargeList() {
  yield takeLatest(
    project.PROJECT_PERSON_INCHARGE_LIST,
    personInchargeListAsync
  );
}

function* NFPistAsync(action: any) {
  try {
    const data = yield call(getNFPList, action);
    yield put({
      type: project.PROJECT_NFP_PARTNERS_LIST_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchNFPList() {
  yield takeLatest(project.PROJECT_NFP_PARTNERS_LIST, NFPistAsync);
}

function* projectListAsync(action: any) {
  try {
    const data = yield call(getProjectList, action);
    yield put({
      type: project.PROJECT_LIST_ASYNC,
      data: data.data,
      status: data.status,
      message: data.message,
      total_Load: data.total_Load,
      total_all: data.total_all
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProjectList() {
  yield takeLatest(project.PROJECT_LIST, projectListAsync);
}

function* getProjectOverviewAsync(action: any) {
  try {
    const data = yield call(getProjectOverview, action);
    yield put({
      type: project.PROJECT_OVERVIEW_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProjectOverview() {
  yield takeLatest(project.PROJECT_OVERVIEW, getProjectOverviewAsync);
}
