import { usersType } from "./users-type";
const initialState = {
  childs: [
    {
      label: "Users",
      value: "users",
      route: "/management/users/users"
    }
    // {
    //   label: "Users Groups",
    //   value: "users-group",
    //   route: "/management/users/users-groups"
    // },
    // {
    //   label: "Capabilities",
    //   value: "capabilities",
    //   route: "/management/users/capabilities"
    // }
  ],
  general_data: {
    view_by: [
      { value: "ctz", label: "Recently Created" },
      { value: "pna", label: "Title:A-to-Z" },
      { value: "pnz", label: "Title:Z-to-A" }
    ],
    user_group: [
      { value: 1, label: "Super Admin" },
      { value: 2, label: "Administrator" },
      { value: 3, label: "Default User" }
    ],

    company: [
      // { value: 1, label: "Dasar Jati Sdn. Bhd" },
      // { value: 2, label: "Xplode Media Sdn. Bhd" }
    ],
    department: [
      // { value: 1, label: "Finance" },
      // { value: 2, label: "Operations management" }
    ],
    gender: [
      // { value: "male", label: "Male" },
      // { value: "female", label: "Female" }
    ],
    // unit: [
    //   { value: 1, label: "Admin only" },
    //   { value: 2, label: "Users without admin" }
    // ],
    account_expiry: [
      { value: "now", label: "Today" },
      { value: "twk", label: "This Week" },
      { value: "tmn", label: "This Month" },
      { value: "exp", label: "Expired" }
    ],
    user_status: [
      { value: 1, label: "Active" },
      { value: 0, label: "Inactive" }
    ]
  },
  users: {
    total_all: 0,
    total_load: 0,
    message: "",
    status: undefined,
    list: [
      // {
      //   user: {
      //     name: "Mohammad  Izzuddin",
      //     account_expiry: {
      //       date: "Nov 31,2021"
      //     },
      //     image: "http://xcelearn.com.my/static/icons/users/1.jpg"
      //   },
      //   contact: {
      //     number: "+6 012 12131313",
      //     email: "izzuddin@djati.com.my"
      //   },
      //   company: {
      //     value: "Finance",
      //     label: "Dasar Jati Sdn. Bhd"
      //   },
      //   department: {
      //     value: "Finance",
      //     label: "Finance"
      //   },
      //   role: "Administrator",
      //   registered: {
      //     date: "Nov 31,2021",
      //     by: "Madam"
      //   },
      //   last_updated: {
      //     date: " Nov 31,2020",
      //     by: "updated by Madam"
      //   }
      // }
    ]
  }
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case usersType.GET_USER_LIST_ASYNC:
      state.users.list = action.data;
      state.users.status = action.status;
      state.users.message = action.message;
      state.users.total_all = action.total_all;
      state.users.total_load = action.total_load;
      return { ...state };
    case usersType.GET_USER_COMPANY_LIST_ASYNC:
      state.general_data.company = action.data;
      return { ...state };
    case usersType.GET_USER_DEPARTMENT_LIST_ASYNC:
      state.general_data.department = action.data;
      return { ...state };
    case usersType.GET_USER_GENDER_LIST_ASYNC:
      state.general_data.gender = action.data;
      return { ...state };
    default:
      return state;
  }
};
export default todos;
