import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import MainLayout from "../containers/layouts/main-wrapper";

import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import { PrivateRoute } from "./private-route";
import { CheckPrivateRoute } from "./check-private-route";

const Unregister = lazy(() => import("./unregister/index"));
const ForgotPassword = lazy(() => import("./forgot-password/index"));
const Login = lazy(() => import("./login/index"));
const Home = lazy(() => import("./home/index"));
const Dashboard = lazy(() => import("./dashboard/index"));
const Sites = lazy(() => import("./sites/index"));
const SiteView = lazy(() => import("./site-details/index"));
const Project = lazy(() => import("./project/index"));
const ProjectView = lazy(() => import("./project/project-view/index"));
const MyAssignment = lazy(() => import("./my-assignment/index"));
const Logout = lazy(() => import("./logout/index"));
const Users = lazy(() => import("./management/users/index"));
const UsersProfile = lazy(() => import("./management/users/profile/index"));
const Settings = lazy(() => import("./my-account/setting/index"));
const MyProfile = lazy(() => import("./my-account/profile/index"));

function MainRouter() {
  return (
    <Switch>
      <MainLayout>
        <Suspense
          fallback={
            <div className="loading-wrapper">
              <Grid item xs={8} sm={5}>
                <Skeleton height={200} />
                <Skeleton height={50} />
                <Skeleton height={50} animation={false} />
                <Skeleton height={50} animation="wave" />
              </Grid>
            </div>
          }
        >
          <PrivateRoute exact path="/" component={Home} />
          <CheckPrivateRoute exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/unregister" component={Unregister} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/sites/:view?" component={Sites} />
          <PrivateRoute
            exact
            path="/site/:site_slug/:view?"
            component={SiteView}
          />
          <PrivateRoute exact path="/project/:view?" component={Project} />

          <PrivateRoute
            path="/project/view/:slug/:view?"
            component={ProjectView}
          />
          <PrivateRoute
            exact
            path="/my-assignment/:view?"
            component={MyAssignment}
          />
          <PrivateRoute
            exact
            path="/management/users/:view?"
            component={Users}
          />
          <PrivateRoute
            exact
            path="/users/:slug/profile/:view?"
            component={UsersProfile}
          />
          <PrivateRoute
            exact
            path="/my-account/settings"
            component={Settings}
          />
          <PrivateRoute
            exact
            path="/my-account/profile/:view?"
            component={MyProfile}
          />
          {/* <Route exact path='/settings' component={Settings} /> */}
          <PrivateRoute exact path="/logout" component={Logout} />
        </Suspense>
      </MainLayout>
    </Switch>
  );
}

export default MainRouter;
