import { call, takeLatest, put, delay } from "redux-saga/effects";
import { app } from "./type";
import { getUserInit, getUserPermissions } from "./user-axios";

function* getUserInitAsync(action: any) {
  try {
    const data = yield call(getUserInit, action);
    yield put({
      type: app.USER_INIT_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchUserInit() {
  yield takeLatest(app.USER_INIT, getUserInitAsync);
}

function* getUserPermissionsAsync(action: any) {
  try {
    const data = yield call(getUserPermissions, action);
    yield put({
      type: app.USER_PERMISSION_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchUserPermissions() {
  yield takeLatest(app.USER_PERMISSION, getUserPermissionsAsync);
}
