import { GD_type } from "./general-data-type";

const initialState = {
  users: {
    is_loading: false,
    data: [],
  },
  state: {
    is_loading: false,
    data: [],
  },
  area: {
    is_loading: false,
    data: [],
  },
  companies: {
    is_loading: false,
    data: [],
  },
  tower_structures: {
    is_loading: false,
    data: [],
  },
  sites_type: {
    is_loading: false,
    data: [],
  },
  sites_land_category: {
    is_loading: false,
    data: [],
  },
  property_building_status: {
    is_loading: false,
    data: [],
  },
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case GD_type.GD__USER_LIST_ASYNC:
      state.users.is_loading = action.is_loading;
      if (!action.is_loading) {
        state.users.data = action.data.data;
      }
      return { ...state };
    case GD_type.GD__STATE_LIST_ASYNC:
      state.state.is_loading = action.is_loading;
      if (!action.is_loading) {
        state.state.data = action.data.data;
      }
      return { ...state };
    case GD_type.GD__AREA_LIST_ASYNC:
      state.area.is_loading = action.is_loading;
      if (!action.is_loading) {
        state.area.data = action.data.data;
      }
      return { ...state };
    case GD_type.GD__COMPANY_LIST_ASYNC:
      state.area.is_loading = action.is_loading;
      if (!action.is_loading) {
        state.companies.data = action.data.data;
      }
      return { ...state };
    case GD_type.GD__TOWER_STRUCTURE_LIST_ASYNC:
      state.tower_structures.is_loading = action.is_loading;
      if (!action.is_loading) {
        state.tower_structures.data = action.data.data;
      }
      return { ...state };
    case GD_type.GD__SITES_TYPE_LIST_ASYNC:
      state.sites_type.is_loading = action.is_loading;
      if (!action.is_loading) {
        state.sites_type.data = action.data.data;
      }
      return { ...state };
    case GD_type.GD__SITE_LAND_CATEGORY_LIST_ASYNC:
      state.sites_land_category.is_loading = action.is_loading;
      if (!action.is_loading) {
        state.sites_land_category.data = action.data.data;
      }
      return { ...state };
    case GD_type.GD__PROPERTY_BUILDING_STATUS_LIST_ASYNC:
      state.property_building_status.is_loading = action.is_loading;
      if (!action.is_loading) {
        state.property_building_status.data = action.data.data;
      }
      return { ...state };
    default:
      return state;
  }
};
export default todos;
