import React from "react";
import { app } from "./type";
import Axios from "axios";
import { authHeader } from "../services/auth-header";

export const getUserInit = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/my-account/init`, authHeader())
    .then((resp) => resp.data)
    .catch((err) => {
      localStorage.removeItem("user");
      window.location.href = "/";
      throw err;
    });
};

export const getUserPermissions = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/my-account/permissions`, authHeader())
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};
