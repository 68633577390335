import { all } from "redux-saga/effects";

import { watchUserLogin, watchUserLogout } from "../services/saga-user-service";
import {
  watchProjectCategoryList,
  watchProjectPersonInchargeList,
  watchProjectSubconList,
  watchNFPList,
  watchProjectList,
  watchProjectOverview,
} from "../routes/project/store/project-saga";
import {
  watchProjectById,
  watchProjectMilestoneList,
  watchProjectTaskList,
  watchPriorityList,
  watchProjectMilestoneListSelection,
  watchProjectView,
  watchProjectViewActivityAsync,
} from "../routes/project/store/project-view-saga";
import {
  watchTaskMessageList,
  watchTaskToDoList,
  watchTaskParticipateList,
  watchTaskActivities,
  watchTaskStatus,
} from "../routes/project/store/task-saga";
import {
  watchMyProfile,
  watchMyActivities,
  watchProfileActivitiesDashboard,
  watchProfileOverviewDashboard,
} from "../routes/my-account/store/profile-saga";
import {
  watchAssignmentList,
  watchMyProjectListList,
  watchAssignmentActivity,
  watchAssignmentOverview,
} from "../routes/my-assignment/store/assignment-saga";
import {
  watchManagementUsersList,
  watchUsersCompanyList,
  watchUsersDepartmentList,
  watchUsersGenderList,
} from "../routes/management/users/store/users_saga";
import {
  watchManagementUserProfile,
  watchUserActivities,
  watchUserProfileActivitiesDashboard,
  watchUserProfileOverviewDashboard,
} from "../routes/management/users/store/profile-saga";
import { watchUserInit, watchUserPermissions } from "./user-saga";

import { watch_getSitesList as SITES_getSitesList } from "../routes/sites/store/sites-saga";
import {
  SITES_watch_getSiteDetails,
  SITES_watch_getMediaList,
} from "../routes/site-details/store/site-saga";
import {
  GD_watch_getAreaList,
  GD_watch_getCompanyList,
  GD_watch_getLandCategoryList,
  GD_watch_getSiteTypeList,
  GD_watch_getStateList,
  GD_watch_getTowerStructureList,
  GD_watch_getUserList,
  GD_watch_getBuildingStatus,
} from "./general-data/general-data-saga";
export default function* rootSaga() {
  yield all([
    watchUserInit(),
    watchUserPermissions(),
    watchUserLogin(),
    watchUserLogout(),
    watchProjectCategoryList(),
    watchProjectPersonInchargeList(),
    watchProjectSubconList(),
    watchNFPList(),
    watchProjectList(),
    watchProjectById(),
    watchMyProfile(),
    watchMyActivities(),
    watchManagementUsersList(),
    watchManagementUserProfile(),
    watchUserActivities(),
    watchProjectMilestoneList(),
    watchProjectTaskList(),
    watchTaskMessageList(),
    watchTaskToDoList(),
    watchTaskParticipateList(),
    watchUsersCompanyList(),
    watchUsersDepartmentList(),
    watchUsersGenderList(),
    watchAssignmentList(),
    watchMyProjectListList(),
    watchPriorityList(),
    watchProjectMilestoneListSelection(),
    watchTaskActivities(),
    watchTaskStatus(),
    watchProjectView(),
    watchProjectViewActivityAsync(),
    watchAssignmentActivity(),
    watchAssignmentOverview(),
    watchProjectOverview(),
    watchProfileActivitiesDashboard(),
    watchProfileOverviewDashboard(),
    watchUserProfileActivitiesDashboard(),
    watchUserProfileOverviewDashboard(),
    SITES_getSitesList(),
    SITES_watch_getSiteDetails(),
    SITES_watch_getMediaList(),
    GD_watch_getAreaList(),
    GD_watch_getCompanyList(),
    GD_watch_getLandCategoryList(),
    GD_watch_getSiteTypeList(),
    GD_watch_getStateList(),
    GD_watch_getTowerStructureList(),
    GD_watch_getUserList(),
    GD_watch_getBuildingStatus(),
  ]);
}
