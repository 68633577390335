import { call, takeLatest, put, delay } from "redux-saga/effects";
import { usersType } from "./users-type";
import {
  getUserProfile,
  getUserActivity,
  getProfileActivitiesDashboard,
  getProfileOverviewDashboard
} from "./profile-axios";

function* getProfileAsync(action: any) {
  try {
    const data = yield call(getUserProfile, action);
    yield put({
      type: usersType.GET_USER_PROFILE_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchManagementUserProfile() {
  yield takeLatest(usersType.GET_USER_PROFILE, getProfileAsync);
}

function* getUserActivitiesAsync(action: any) {
  try {
    const data = yield call(getUserActivity, action);
    yield put({
      type: usersType.GET_USER_ACTIVITY_ASYNC,
      data: data.data,
      status: data.status,
      message: data.message
    });
  } catch (error) {
    throw error;
  }
}

export function* watchUserActivities() {
  yield takeLatest(usersType.GET_USER_ACTIVITY, getUserActivitiesAsync);
}

function* getUserProfileOverviewDashboardAsync(action: any) {
  try {
    const data = yield call(getProfileOverviewDashboard, action);
    yield put({
      type: usersType.USER_PROFILE_OVERVIEW_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchUserProfileOverviewDashboard() {
  yield takeLatest(
    usersType.USER_PROFILE_OVERVIEW,
    getUserProfileOverviewDashboardAsync
  );
}

function* getUserProfileActivitiesDashboardAsync(action: any) {
  try {
    const data = yield call(getProfileActivitiesDashboard, action);
    yield put({
      type: usersType.USER_PROFILE_OVERVIEW_ACTIVITIES_ASYNC,
      data: data.data,
      start_date: data.start_date
    });
  } catch (error) {
    throw error;
  }
}

export function* watchUserProfileActivitiesDashboard() {
  yield takeLatest(
    usersType.USER_PROFILE_OVERVIEW_ACTIVITIES,
    getUserProfileActivitiesDashboardAsync
  );
}
