import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { myAccount } from "./my-account-type";
import { app } from "../../../stores/type";
import Axios from "axios";
import { authHeader } from "../../../services/auth-header";

export const getUserProfile = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/my-account/profile/about/view`,
    authHeader()
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getUserActivity = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/my-account/activities`, {
    ...authHeader(),
    params: { ...action.params }
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getProfileOverviewDashboard = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/my-account/profile/overview-dashboard`,
    {
      ...authHeader()
    }
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getProfileActivitiesDashboard = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/my-account/profile/activities-dashboard`,
    {
      ...authHeader()
    }
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};
