import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import "./App.scss";
import "./framework.scss";
import variable from "./App-css-variable.scss";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: variable.mainColor,
      contrastText: "#fff",
    },
    secondary: {
      main: "#4DCF3A",
      contrastText: "#fff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "rgb(243, 243, 243)",
    },
  },
});

export default theme;
