import { app } from "./type";

const initialState = {
  status: false, // collape expand
  items: [
    // { icon: "pie_chart", label: "Dashboard", route: "/dashboard" },

    { icon: "ballot", label: "Projects", route: "/project" },
    { icon: "assignment", label: "My Assignments", route: "/my-assignment" },
    { icon: "location_on", label: "Sites", route: "/sites/sites" },
    // { icon: "satellite", label: "Sites", route: "/site" },
    // { icon: "contact_support", label: "Tickets", route: "/ticket" },
    // { icon: "ballot", label: "Maintenance", route: "/maintenance" }
  ],
  management: {
    icon: "widgets",
    label: "Management",
    route: null,
    childs: [
      {
        icon: "supervised_user_circle",
        label: "Users",
        route: "/management/users/users",
      },
      // { icon: "apartment", label: "Companies", route: "/admin/companies" },
      // {
      //   icon: 'assignment_ind',
      //   label: 'Departments',
      //   route: '/admin/departments'
      // },
      // { icon: "assignment_turned_in", label: "Status", route: "/admin/status" }
    ],
  },
  account: {
    icon: "person_outline",
    label: "My Account",
    route: null,
    childs: [
      {
        icon: "supervised_user_circle",
        label: "Profile",
        route: "/my-account/profile/overview",
      },
      // {
      //   icon: "supervised_user_circle",
      //   label: "Settings",
      //   route: "/my-account/settings"
      // }
      // { icon: "apartment", label: "Companies", route: "/admin/companies" },
      // {
      //   icon: 'assignment_ind',
      //   label: 'Departments',
      //   route: '/admin/departments'
      // },
      // { icon: "assignment_turned_in", label: "Status", route: "/admin/status" }
    ],
  },
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case app.DRAWER_TOOGLE:
      state.status = action.status;
      return { ...state };
    default:
      return state;
  }
};
export default todos;
