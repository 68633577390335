export enum myAccount {
  GET_MY_PROFILE = "GET_MY_PROFILE",
  GET_MY_PROFILE_ASYNC = "GET_MY_PROFILE_ASYNC",
  GET_MY_ACTIVITY = "GET_MY_ACTIVITY",
  GET_MY_ACTIVITY_ASYNC = "GET_MY_ACTIVITY_ASYNC",
  MY_PROFILE_OVERVIEW = "MY_PROFILE_OVERVIEW",
  MY_PROFILE_OVERVIEW_ASYNC = "MY_PROFILE_OVERVIEW_ASYNC",
  MY_PROFILE_OVERVIEW_ACTIVITIES = "MY_PROFILE_OVERVIEW_ACTIVITIES",
  MY_PROFILE_OVERVIEW_ACTIVITIES_ASYNC = "MY_PROFILE_OVERVIEW_ACTIVITIES_ASYNC"
}
