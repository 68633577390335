export enum GD_type {
  GD__USER_LIST = "GD__USER_LIST",
  GD__USER_LIST_ASYNC = "GD__USER_LIST_ASYNC",
  GD__COMPANY_LIST = "GD__COMPANY_LIST",
  GD__COMPANY_LIST_ASYNC = "GD__COMPANY_LIST_ASYNC",
  GD__TOWER_STRUCTURE_LIST = "GD__TOWER_STRUCTURE_LIST",
  GD__TOWER_STRUCTURE_LIST_ASYNC = "GD__TOWER_STRUCTURE_LIST_ASYNC",
  GD__SITES_TYPE_LIST = "GD__SITES_TYPE_LIST",
  GD__SITES_TYPE_LIST_ASYNC = "GD__SITES_TYPE_LIST_ASYNC",
  GD__SITE_LAND_CATEGORY_LIST = "GD__SITE_LAND_CATEGORY_LIST",
  GD__SITE_LAND_CATEGORY_LIST_ASYNC = "GD__SITE_LAND_CATEGORY_LIST_ASYNC",
  GD__STATE_LIST = "GD__STATE_LIST",
  GD__STATE_LIST_ASYNC = "GD__STATE_LIST_ASYNC",
  GD__AREA_LIST = "GD__STATE_AREA_LIST",
  GD__AREA_LIST_ASYNC = "GD__STATE_AREA_LIST_ASYNC",
  GD__PROPERTY_BUILDING_STATUS_LIST = "GD__PROPERTY_BUILDING_STATUS_LIST",
  GD__PROPERTY_BUILDING_STATUS_LIST_ASYNC = "GD__PROPERTY_BUILDING_STATUS_LIST_ASYNC",
}
