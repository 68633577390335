import { app } from "../../../../stores/type";
import Axios from "axios";
import { authHeader } from "../../../../services/auth-header";

export const getUserList = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/management/users/list`, {
    ...authHeader(),
    params: { ...action.params }
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getCompanyList = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/management/users/company/list`, {
    ...authHeader(),
    params: { ...action.params }
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getDepartmentList = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/management/users/department/list`, {
    ...authHeader(),
    params: { ...action.params }
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getGenderList = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/management/users/gender/list`, {
    ...authHeader(),
    params: { ...action.params }
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};
