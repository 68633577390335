import React from "react";
import { getUserSession } from "./user-service";

export const authHeader = () => {
  // return authorization header with jwt token

  let user = JSON.parse(getUserSession());

  if (user && user.token) {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token
      }
    };
  } else {
    return {};
  }
};

export const authHeaderFormData = () => {
  // return authorization header with jwt token

  let user = JSON.parse(getUserSession());

  if (user && user.token) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: user.token
      }
    };
  } else {
    return {};
  }
};
