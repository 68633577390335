import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { app } from "../stores/type";
import Axios from "axios";
import { authHeader } from "./auth-header";

export const getUserSession = () => {
  return localStorage.getItem("user");
};

export const checkUserSession = () => {
  //const redux = useSelector((state: any) => state.redux);
  return localStorage.getItem("user");
};

export const userLogin = (action: any) => {
  return Axios.post(`${app.API_URL_ORIGIN}/login`, {
    email: action.email,
    password: action.password
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const userLogout = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/logout`, authHeader())
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};
