import { app } from './type';

const initialState: any = {
    login: {
        status: false, //logged or not
    },
    user: {},
    permissions: {},
    user_socket: null,
};

const todos = (state = initialState, action: any) => {
    switch (action.type) {
        case app.LOGIN_STATUS_ASYNC:
            delete action.type;
            state.login = { ...state.login, ...action };
            return { ...state };
        case app.LOGOUT_STATUS_ASYNC:
            state.login.status = action.status;
            return { ...state };
        case app.USER_INIT_ASYNC:
            state.user = action.data;
            return { ...state };
        case app.USER_PERMISSION_ASYNC:
            state.permissions = { ...state.permissions, ...action.data };
            return { ...state };
        case app.GET_USER_SOCKET:
            state.user_socket = action.user_socket;
            return { ...state };
        default:
            return state;
    }
};
export default todos;
