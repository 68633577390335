import { app } from './type';

const initialState = {
    title: 'TeleNet',
    subtitle: 'Tower Management System',
    desc: 'Site Monitoring & Maintenance Solution',
};

const todos = (state = initialState, action: any) => {
    switch (action.type) {
        default:
            return state;
    }
};
export default todos;
