import { call, takeLatest, put, delay, takeEvery } from "redux-saga/effects";
import { projectView } from "./project-view-type";
import {
  getTaskMessageList,
  getTaskToDoList,
  getTaskActivities,
  getTaskStatus
} from "./task-axios";
import { getProjectParticipateList } from "./project-view-axios";

function* taskMessageListAsync(action: any) {
  try {
    const data = yield call(getTaskMessageList, action);
    yield put({
      type: projectView.TASK_MESSAGE_LIST_ASYNC,
      data: data.data,
      status: data.status
    });
  } catch (error) {
    throw error;
  }
}

export function* watchTaskMessageList() {
  yield takeLatest(projectView.TASK_MESSAGE_LIST, taskMessageListAsync);
}

function* taskToDoListAsync(action: any) {
  try {
    const data = yield call(getTaskToDoList, action);
    yield put({
      type: projectView.TASK_TO_DO_LIST_ASYNC,
      data: data.data,
      status: data.status,
      completed: data.completed
    });
  } catch (error) {
    throw error;
  }
}

export function* watchTaskToDoList() {
  yield takeLatest(projectView.TASK_TO_DO_LIST, taskToDoListAsync);
}

function* taskParticipateListAsync(action: any) {
  try {
    const data = yield call(getProjectParticipateList, action);
    yield put({
      type: projectView.GET_TASK_PARTICIPATE_LIST_ASYNC,
      data: data.data,
      status: data.status
    });
  } catch (error) {
    throw error;
  }
}

export function* watchTaskParticipateList() {
  yield takeLatest(
    projectView.GET_TASK_PARTICIPATE_LIST,
    taskParticipateListAsync
  );
}

function* getTaskActivitiesAsync(action: any) {
  try {
    const data = yield call(getTaskActivities, action);
    yield put({
      type: projectView.GET_TASK_ACTIVITY_ASYNC,
      data: data.data,
      status: data.status,
      message: data.message
    });
  } catch (error) {
    throw error;
  }
}

export function* watchTaskActivities() {
  yield takeLatest(projectView.GET_TASK_ACTIVITY, getTaskActivitiesAsync);
}

function* getTaskStatusAsync(action: any) {
  try {
    const data = yield call(getTaskStatus, action);
    yield put({
      type: projectView.GET_TASK_STATUS_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchTaskStatus() {
  yield takeLatest(projectView.GET_TASK_STATUS, getTaskStatusAsync);
}
