import React from "react";
import MainRouter from "../routes";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import Theme from "./App-theme";

function App(props: any) {
  return (
    <ThemeProvider theme={Theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <MainRouter />
    </ThemeProvider>
  );
}

export default App;
