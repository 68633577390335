export enum app {
    DRAWER_TOOGLE = 'DRAWER_TOOGLE',
    LOGIN_STATUS_ASYNC = 'LOGIN_STATUS_ASYNC',
    LOGIN_STATUS = 'LOGIN_STATUS',
    LOGOUT_STATUS_ASYNC = 'LOGOUT_STATUS_ASYNC',
    LOGOUT_STATUS = 'LOGOUT_STATUS',
    USER_INIT = 'USER_INIT',
    USER_INIT_ASYNC = 'USER_INIT_ASYNC',
    USER_PERMISSION = 'USER_PERMISSION',
    USER_PERMISSION_ASYNC = 'USER_PERMISSION_ASYNC',
    API_URL_ORIGIN = 'https://telenetapi.cloudhub.my',
    // API_URL_ORIGIN = 'http://localhost:3330',
    GET_USER_SOCKET = 'GET_USER_SOCKET',
}
