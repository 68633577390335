import React from "react";
import { app } from "../type";
import Axios from "axios";
import { authHeader } from "../../services/auth-header";

const url = `${app.API_URL_ORIGIN}/general-data/`;
export const getUserList = (action: any) => {
  return Axios.get(`${url}users/list`, authHeader())
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};

export const getStateList = (action: any) => {
  return Axios.get(`${url}users/state/list`, authHeader())
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};

export const getAreaList = (action: any) => {
  return Axios.get(`${url}users/area/list/${action.state_id}`, authHeader())
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};

export const getCompanyList = (action: any) => {
  return Axios.get(`${url}users/company/list`, authHeader())
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};

export const getSiteTypeList = (action: any) => {
  return Axios.get(`${url}sites/sites-type/list`, authHeader())
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};

export const getTowerStructureList = (action: any) => {
  return Axios.get(`${url}sites/tower-structure/list`, authHeader())
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};

export const getLandCategoryList = (action: any) => {
  return Axios.get(`${url}sites/property-land-category/list`, authHeader())
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};

export const getBuildingStatus = (action: any) => {
  return Axios.get(`${url}sites/property-building-status/list`, authHeader())
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};
