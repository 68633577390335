import { assignment } from "./assignment-type";

const initialState = {
  childs: [
    {
      label: "Overview",
      value: "overview",
      route: "/my-assignment/overview"
    },
    {
      label: "My Assignments",
      value: "my-assignment",
      route: "/my-assignment/my-assignment"
    }
  ],
  overview: {
    task_progress: [
      {
        name: "Not Started",
        value: 0,
        color: "#FFBB28",
        total: "0",
        donut_label: { value: 0, label: "Task left" }
      },
      {
        name: "Overdue",
        value: 0,
        color: "#FF8042",
        total: "0",
        donut_label: { value: 0, label: "Task left" }
      },
      {
        name: "Upcoming",
        value: 0,
        color: "#0088FE",
        total: "0",
        donut_label: { value: 0, label: "Task left" }
      },
      {
        name: "Completed",
        value: 0,
        color: "#00C49F",
        total: "0",
        donut_label: { value: 0, label: "Task left" }
      }
    ],
    task_priority: [
      {
        name: "High",
        color: "#FF8042",
        total: "0"
      },
      {
        name: "Medium",
        color: "#FFBB28",
        total: "0"
      },
      {
        name: "Normal",
        color: "#0088FE",
        total: "0"
      }
    ],
    task_upcoming: {
      today: { total: 0 },
      this_week: { total: 0 },
      this_month: { total: 0 }
    },
    tasks: { total: 0 },
    projects: { total: 0 }
  },
  activities: {
    start_date: "2019-01-01",
    list: []
  },
  assignment: {
    total_all: 0,
    total_load: 0,
    message: "",
    status: undefined,
    list: []
  },
  general_data: {
    project_list: []
  }
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case assignment.ASSIGNMENT_LIST_ASYNC:
      state.assignment.list = action.data;
      state.assignment.status = action.status;
      state.assignment.message = action.message;
      state.assignment.total_all = action.total_all;
      state.assignment.total_load = action.total_load;
      return { ...state };
    case assignment.MY_PROJECT_lIST_ASYNC:
      state.general_data.project_list = action.data;
      return { ...state };
    case assignment.ASSIGNMENT_OVERVIEW_ASYNC:
      state.overview = action.data;
      return { ...state };
    case assignment.ASSIGNMENT_ACTIVITIES_ASYNC:
      state.activities.list = action.data;
      state.activities.start_date = action.start_date;
      return { ...state };
    default:
      return state;
  }
};
export default todos;
