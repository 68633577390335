import React from "react";

import { app } from "../../../../stores/type";
import Axios from "axios";
import { authHeader } from "../../../../services/auth-header";

export const getUserProfile = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/management/users/about/view/${action.id}`,
    authHeader()
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getUserActivity = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/management/users/activities/${action.id}`,
    {
      ...authHeader(),
      params: { ...action.params }
    }
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getProfileOverviewDashboard = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/management/users/${action.id}/profile/overview-dashboard`,
    {
      ...authHeader()
    }
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getProfileActivitiesDashboard = (action: any) => {
  return Axios.get(
    `${app.API_URL_ORIGIN}/management/users/${action.id}/profile/activities-dashboard`,
    {
      ...authHeader()
    }
  )
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};
