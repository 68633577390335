import { call, takeLatest, put, delay, takeEvery } from "redux-saga/effects";
import { projectView } from "./project-view-type";
import {
  getProjectbyId,
  getMilestoneList,
  getTaskList,
  getPriorityList,
  getMilestoneListSelection,
  getProjectViewOverview,
  getProjectViewActivities
} from "./project-view-axios";

function* projectByIdAsync(action: any) {
  try {
    const data = yield call(getProjectbyId, action);
    yield put({
      type: projectView.PROJECT_VIEW_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProjectById() {
  yield takeLatest(projectView.PROJECT_VIEW, projectByIdAsync);
}

function* projectMilestoneListAsync(action: any) {
  try {
    const data = yield call(getMilestoneList, action);
    yield put({
      type: projectView.PROJECT_MILESTONE_LIST_ASYNC,
      data: data.data,
      message: data.message,
      status: data.status,
      total_load: data.total_load,
      total_all: data.total_all
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProjectMilestoneList() {
  yield takeLatest(
    projectView.PROJECT_MILESTONE_LIST,
    projectMilestoneListAsync
  );
}

function* projectMilestoneListSelectionAsync(action: any) {
  try {
    const data = yield call(getMilestoneListSelection, action);
    yield put({
      type: projectView.PROJECT_MILESTONE_LIST_SELECTION_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProjectMilestoneListSelection() {
  yield takeLatest(
    projectView.PROJECT_MILESTONE_LIST_SELECTION,
    projectMilestoneListSelectionAsync
  );
}

function* projectTaskListAsync(action: any) {
  try {
    const data = yield call(getTaskList, action);
    yield put({
      type: projectView.PROJECT_TASK_LIST_ASYNC,
      data: data.data,
      status: data.status,
      milestone_id: "milestone_" + action.milestone_id,
      message: data.message,
      total_load: data.total_load,
      total_all: data.total_all
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProjectTaskList() {
  yield takeEvery(projectView.PROJECT_TASK_LIST, projectTaskListAsync);
}

function* getPriorityListAsync(action: any) {
  try {
    const data = yield call(getPriorityList, action);
    yield put({
      type: projectView.PRIORITY_LIST_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchPriorityList() {
  yield takeLatest(projectView.PRIORITY_LIST, getPriorityListAsync);
}

function* getProjectViewAsync(action: any) {
  try {
    const data = yield call(getProjectViewOverview, action);
    yield put({
      type: projectView.PROJECT_VIEW_OVERVIEW_ASYNC,
      data: data.data
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProjectView() {
  yield takeLatest(projectView.PROJECT_VIEW_OVERVIEW, getProjectViewAsync);
}

function* getProjectViewActivityAsync(action: any) {
  try {
    const data = yield call(getProjectViewActivities, action);
    yield put({
      type: projectView.PROJECT_VIEW_ACTIVITIES_ASYNC,
      data: data.data,
      start_date: data.start_date
    });
  } catch (error) {
    throw error;
  }
}

export function* watchProjectViewActivityAsync() {
  yield takeLatest(
    projectView.PROJECT_VIEW_ACTIVITIES,
    getProjectViewActivityAsync
  );
}
