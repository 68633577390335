import { types } from "./site-type";
const initialState: any = {
  is_loading: true,
  data: {
    real_estate_agency: {
      value: "1",
      label: "Dasar Jati Sdn Bhd",
    },
    res_agent: {
      value: "1",
      label: "Akmal",
    },
    phone_res_agent: "013-2456314",
    site: {
      name: "Surau Ehsaniah",
      type: { value: "1", label: "FLATLAND" },
      address: {
        state: { value: 1, label: "Selangor" },
        area: { value: 1, label: "Bandar Baru Bangi" },
        postcode: "34560",
        address_detail: "Lot 15502, mukim tanjung 12",
      },
      lat: "2.9198° N",
      long: "101.7809° E",
    },
    location: {
      distance_to_residential: "10 m",
      has_piling_required: { value: 1, label: "YES" },
      has_power_supply: { value: 1, label: "YES" },
      distance_to_power: "5 m",
      has_major_earthwork: { value: 1, label: "YES" },
      road_access: { value: 1, label: "YES" },
      distance_to_road: "34 m",
    },
    property: {
      land_lord: "Haji Aris",
      contact_person: "En Razak",
      contact_person_number: "012-3772554",
      contact_person_email: null,
      contact_person_address: {
        state: null,
        area: null,
        postcode: null,
        address_detail: null,
      },
      owner_category: {
        value: 1,
        label: "PRIVITE LAND",
      },
      building_status: {
        value: 1,
        label: "Commercial",
      },
      rental: "5,000 per month",
    },
    structure: {
      category: { value: 1, label: "Ground Based Towers" },
      feature: [
        "Lattice configuration ",
        "3 legged structure",
        "Leg and bracing consist of pipe (CHS) sections",
        "Macro coverage requirements ",
      ],
      space_size: "7m x 7m",
      height_range: "30m – 50m",
      num_tenants: "3 tenants",
    },
    gallery: {
      images: [
        {
          value: 1,
          label: "Tower 1",
          image: {
            small: "https://pins.com.my/wp-content/uploads/2019/01/01-2.png",
            medium: "https://pins.com.my/wp-content/uploads/2019/01/01-2.png",
            large: "https://pins.com.my/wp-content/uploads/2019/01/01-2.png",
          },
        },
        {
          value: 1,
          label: "Tower 1 front",
          image: {
            small: "https://pins.com.my/wp-content/uploads/2019/01/03-1.jpg",
            medium: "https://pins.com.my/wp-content/uploads/2019/01/03-1.jpg",
            large: "https://pins.com.my/wp-content/uploads/2019/01/03-1.jpg",
          },
        },
        {
          value: 1,
          label: "Tower 1",
          image: {
            small: "https://pins.com.my/wp-content/uploads/2019/01/01-2.png",
            medium: "https://pins.com.my/wp-content/uploads/2019/01/01-2.png",
            large: "https://pins.com.my/wp-content/uploads/2019/01/01-2.png",
          },
        },
        {
          value: 1,
          label: "Tower 1 front",
          image: {
            small: "https://pins.com.my/wp-content/uploads/2019/01/03-1.jpg",
            medium: "https://pins.com.my/wp-content/uploads/2019/01/03-1.jpg",
            large: "https://pins.com.my/wp-content/uploads/2019/01/03-1.jpg",
          },
        },
      ],
    },
  },
  site_media: {
    total_all: 0,
    total_load: 0,
    message: "",
    is_loading: true,
    status: undefined,
    data: [],
  },
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SITES_DETAILS_ASYNC:
      state.is_loading = action.is_loading;
      if (!state.is_loading) {
        state.data = action.data.data;
      }

      return { ...state };

    case types.SITE_MEDIA_LIST_ASYNC:
      state.site_media.is_loading = action.is_loading;
      if (!action.is_loading) {
        state.site_media.data = action.data.data;
        state.site_media.status = action.data.status;
        state.site_media.message = action.data.message;
        state.site_media.total_all = action.data.total_all;
        state.site_media.total_load = action.data.total_load;
      }

      return { ...state };

    default:
      return state;
  }
};
export default todos;
