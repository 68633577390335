import React from "react";
import { app } from "../../../stores/type";
import Axios from "axios";
import { authHeader } from "../../../services/auth-header";

export const getTaskList = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/my-account/assignment/list`, {
    ...authHeader(),
    params: { ...action.params }
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getProjectList = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/my-account/project/list`, {
    ...authHeader()
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getAssignmentOverview = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/my-account/assignment/overview`, {
    ...authHeader()
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};

export const getAssignmentActivities = (action: any) => {
  return Axios.get(`${app.API_URL_ORIGIN}/my-account/assignment/activities`, {
    ...authHeader()
  })
    .then(resp => resp.data)
    .catch(err => {
      throw err;
    });
};
