export enum assignment {
  ASSIGNMENT_LIST = "ASSIGNMENT_LIST",
  ASSIGNMENT_LIST_ASYNC = "ASSIGNMENT_LIST_ASYNC",
  MY_PROJECT_lIST = "MY_PROJECT_lIST",
  MY_PROJECT_lIST_ASYNC = "MY_PROJECT_lIST_ASYNC",
  ASSIGNMENT_OVERVIEW = "ASSIGNMENT_OVERVIEW",
  ASSIGNMENT_OVERVIEW_ASYNC = "ASSIGNMENT_OVERVIEW_ASYNC",
  ASSIGNMENT_ACTIVITIES = "ASSIGNMENT_ACTIVITIES",
  ASSIGNMENT_ACTIVITIES_ASYNC = "ASSIGNMENT_ACTIVITIES_ASYNC"
}
